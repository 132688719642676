<template>
  <div>
    二维码中转站页面
  </div>
</template>

<script>
import {getLoginQrCodeImg} from "@/api/claim";
import {Dialog} from "vant";

export default {
  name: 'QRTempView',
  data() {
    return {
      qrCodeBase64: '',
      token: ''
    }
  },

  mounted() {
    this.loginQrCodeImg();
  },

  methods: {
    loginQrCodeImg() {
      this.spinning = true;
      this.claimCode = this.$route.query.claimCode;
      getLoginQrCodeImg({claimCode: this.claimCode}).then(res => {
        this.spinning = false;
        if (200 === res.code) {
          this.qrCodeBase64 = 'data:image/jpeg;base64, ' + res.data.img;
          this.token = res.data.token;
          window.location.href = 'tmri://12123?ywlx=9921&token=' + this.token;
        } else {
          // Notify({type: 'danger', message: res.message?res.message:"信息错误"});
          var tips = '授权二维码获取失败[' + (res.message ? res.message : "未知错误") + ']，请点击重新获取！';
          Dialog.alert({
            title: '提示',
            message: tips,
          }).then(() => {
            this.loginQrCodeImg();
          }).catch(() => {
            // on cancel
          });
        }
      }).catch(() => {
        this.spinning = false;
        // Notify({type: 'danger', message: "接口请求失败，请刷新重新页面！"});
        Dialog.alert({
          title: '提示',
          message: '接口请求失败，请点击重新获取！',
        }).then(() => {
          this.loginQrCodeImg();
        });
      }).finally(() => {
        this.spinning = false;
      });
    }
  }
}
</script>